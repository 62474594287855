import React, { useState } from 'react';
import { Row, Col } from "react-flexbox-grid";
// SCSS
import "./hero.scss";
import Feature from './Feature';
import Modal from './Modal';
//Components
import Title from "../ui-components/title/title";
import Per01 from "../../assets/hero/CleanWater.jpeg";
import Per02 from "../../assets/hero/WomenProgram.jpeg";
import Per03 from "../../assets/hero/ClimateChange.jpeg";
import Per04 from "../../assets/hero/youth.jpg";
import Per05 from "../../assets/hero/HealthProgram.jpg";
import Per06 from "../../assets/hero/education.jpg";
import Per07 from "../../assets/hero/Community.jpg";

const Services = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const features = [
    { servicesImg: Per01, text: 'The Clean Water and Sanitation Program', content: (<> <h1>The Clean Water and Sanitation Program</h1>
  <p>Access to clean water and proper sanitation are fundamental human rights essential for life dignity and well-being. Yet millions of people around the world especially in marginalized communities are denied these basic necessities. The consequences are devastating: countless lives lost families torn apart and communities trapped in cycles of poverty and disease. But we believe that change is possible.</p>
    <p>We envision a world where every individual has access to safe water sanitation and hygiene (WASH) facilities and where communities can thrive in health dignity and hope. Our water and sanitation program is a crucial step towards realizing this vision. By partnering with local communities schools and governments we aim to provide sustainable WASH solutions that transform lives promote education and foster economic growth.</p>
    <p><b>Our scope of work includes:</b></p>
    <p><b>Our Water Development Program</b></p>
    <p>1. Water Source Assessments and Feasibility Studies</p>
    <p>We identify potential water sources (wells boreholes rivers etc.) through a combination of desktop research field observations and community engagement. Our assessments involve:</p>
    <p>Reviewing existing data and literature on hydrology geology and water resources</p>
    <p>Consulting with local communities stakeholders and experts to gather insights and concerns</p>
    <p>Conducting field visits to potential water sources to assess their feasibility</p>
    <p>Collecting and analyzing water samples for laboratory testing</p>
    <p>Measuring water flow and volume to determine yield and sustainability</p>
    <p>Evaluating technical and financial viability including costs and funding opportunities</p>
    <p>Considering environmental and social impacts such as potential effects on ecosystems and community livelihoods</p>
    <p>2. Solar-Powered Wells and Boreholes</p>
    <p>We drill and construct wells and boreholes using specialized equipment and techniques ensuring proper well casing and screening. Our solar-powered pumping systems include:</p>
    <p>Designing and installing solar panel arrays to power submersible pumps</p>
    <p>Selecting appropriate pump sizes and types for optimal water flow and pressure</p>
    <p>Implementing control systems for efficient operation and maintenance</p>
    <p>Ensuring proper well construction and safety including casing screening and cementing</p>
    <p>Complying with local building codes and regulations and obtaining necessary permits</p>
    <p>3. Shallow Well Construction and Renovation</p>
    <p>We repair and refurbish existing shallow wells to improve their safety and functionality. Our renovation process involves:</p>
    <p>Cleaning and disinfecting well components including pipes and fittings</p>
    <p>Replacing worn or damaged parts such as pumps and valves</p>
    <p>Improving well casing and screening to prevent contamination</p>
    <p>Ensuring proper well construction and safety including cementing and grouting</p>
    <p>Implementing safety measures during construction such as fencing and signage</p>
    <p>4. Rainwater Harvesting Systems</p>
    <p>We design and install rainwater collection and storage systems including:</p>
    <p>Roof catchment and gutter systems to collect and convey rainwater</p>
    <p>Storage tanks and piping to store and distribute rainwater</p>
    <p>First flush devices to divert contaminants and debris</p>
    <p>Water treatment technologies such as filtration and UV disinfection</p>
    <p>Implementing proper slope and drainage to prevent waterlogging and erosion</p>
    <p>5. Water Treatment and Purification Systems</p>
    <p>We design and install water treatment plants to remove contaminants and pathogens from water. Our treatment processes include:</p>
    <p>Coagulation and flocculation to remove suspended solids and turbidity</p>
    <p>Sedimentation and filtration to remove particulate matter and bacteria</p>
    <p>Disinfection using UV chlorine or other approved methods</p>
    <p>Storage and distribution systems to supply treated water to communities</p>
    <p>6. Water Storage Tanks and Distribution Networks</p>
    <p>We build water storage tanks and reservoirs to store treated water designing them to meet water demand and ensure proper pressure and flow. Our distribution networks include:</p>
    <p>Water pipes and fittings sized and materials selected for optimal flow and pressure</p>
    <p>Valves and control systems for efficient operation and maintenance</p>
    <p>Metering and monitoring systems to track water usage and quality</p>
    <p>Implementing proper pipe sizing and layout to minimize losses and ensure reliability</p>
    <p>7. Water Quality Testing and Monitoring</p>
    <p>We conduct regular water quality testing (quarterly) to ensure the safety and quality of water supplied to communities. Our testing includes:</p>
    <p>Bacterial testing (total coliform E. coli etc.)</p>
    <p>Chemical testing (pH turbidity etc.)</p>
    <p>Physical testing (temperature conductivity etc.)</p>
    <p>Monitoring water quality parameters and alerting operators to any issues</p>
    <p>Implementing corrective actions and maintenance schedules as needed</p>
    <p><b>Our Sanitation Program</b></p>
    <p>1. School Latrine Construction and Renovation</p>
    <p>We construct and renovate latrines in our partner community schools to improve sanitation and hygiene for students and teachers. Our school latrine construction and renovation include:</p>
    <p>Building new latrines with proper ventilation and drainage</p>
    <p>Renovating existing latrines to improve safety and functionality</p>
    <p>Ensuring proper latrine design and construction including flooring walls and roofs</p>
    <p>Implementing proper waste management systems including pit latrines and septic tanks</p>
    <p>2. School Handwashing Station Installation</p>
    <p>We install handwashing stations in our partner community schools to promote hygiene and prevent the spread of diseases. Our school handwashing station installation includes:</p>
    <p>Building handwashing stations with clean water sources and drainage</p>
    <p>Installing soap dispensers and toilet paper holders</p>
    <p>Ensuring proper handwashing station design and construction including flooring and roofs</p>
    <p>Implementing proper waste management systems including graywater disposal</p>
    <p>3. School Sanitation and Hygiene Education</p>
    <p>We educate students teachers and school administrators on proper sanitation and hygiene practices to create a healthy and safe learning environment. Our school sanitation and hygiene education include:</p>
    <p>Conducting workshops and training sessions on handwashing toilet use and waste management</p>
    <p>Distributing educational materials and resources on sanitation and hygiene</p>
    <p>Encouraging student participation in sanitation and hygiene promotion</p>
    <p>Collaborating with school administrators to develop sanitation and hygiene policies</p>
    <p>As you read through our scope of work we invite you to join us on this journey. Discover how our program is designed to address the complex challenges in water and sanitation and how your support can make a tangible difference in the lives of thousands. Together let us create a brighter future where every person has access to the most basic yet most essential necessities of life.</p>
   </>) },
    { servicesImg: Per02, text: "The Women's Empowerment Program", content: (<> <h1>The Women's Empowerment Program</h1>  <p>Empowering women and girls is a moral obligation and a strategic investment in a future where everyone can thrive. When women and girls are empowered they become unstoppable forces for change driving economic growth social progress and sustainable development. But despite the progress made gender inequality still persists denying millions of women and girls the chance to reach their full potential.</p>
    <p>That's why our Women Empowerment Program is dedicated to tackling the complex challenges that hold women and girls back. We believe that every woman and girl deserves the opportunity to live a life that is free from violence discrimination and inequality. Our program provides the support resources and tools necessary to unlock their full potential and empower them to take control of their lives.</p>
    <p>Through our program we aim to create a world where women and girls are valued respected and empowered to reach their full potential. A world where they can make informed decisions about their economic well-being achieve stability and independence and live a life that is free from violence and discrimination.</p>
    <p>We invite you to join us on this journey towards a more just and equitable society. Please take a moment to review our work in this program and learn how you can support our efforts to empower women and girls and create a brighter future for all.</p>
    <p><b>Our Scope of our work includes</b></p>
    <p><b>a. Leadership Development</b></p>
    <p>We provide training sessions on leadership communication and problem-solving skills to empower women and girls to take on leadership roles in their communities. We offer mentorship and coaching for personalized support and guidance encouraging them to take on leadership roles in community projects and initiatives.</p>
    <p><b>b. Economic Empowerment,</b></p>
    <p>We provide access to education and training on financial literacy budgeting and entrepreneurship equipping women and girls with the skills to achieve economic independence. We offer mentorship and coaching on career development and job readiness facilitating access to microfinance and other financial services. We support income-generating activities and business development promoting financial inclusion and economic independence.</p>
    <p><b>c. Human Rights Education and Advocacy</b></p>
    <p>We conduct workshops on human rights gender equality and women's empowerment training women and girls on their legal rights and entitlements. We provide support for survivors of gender-based violence and advocate for policy change and legal protection.</p>
    <p><b>d. Mentorship and Coaching</b></p>
    <p>We offer one-on-one and group mentorship and coaching sessions providing personalized support and guidance for education and career goals.</p>
    <p><b>e. Community Engagement and Outreach</b></p>
    <p>We engage in community outreach and engagement sessions promoting program activities and objectives through traditional media social media and other channels. We collaborate with community leaders and organizations to achieve program objectives.</p>
    <p><b>f. Capacity Building for Local Partners</b></p>
    <p>We provide training and capacity building for local partners offering technical assistance and support for program implementation and management. We partner with local organizations to achieve program objectives.</p>
    <p>Together we can make a difference and create a world that is more just equitable and prosperous for all. A world where women and girls are empowered to reach their full potential and live a life that is free from violence discrimination and inequality.</p>
    <p>Join us in empowering women and girls to unlock their full potential and create a brighter future for themselves their families and their communities. Together let's break down barriers challenge gender stereotypes and build a world that is more just equitable and prosperous for all.</p>
</>) },
    { servicesImg: Per03, text: 'The Climate Change Resilience Program', content: (<> <h1>The Climate Change Resilience Program</h1>
    
    <p>Imagine a world where communities are equipped to thrive in the face of climate change. Where environmental conservation and sustainable agriculture practices ensure a resilient food supply. Where climate-related disasters are met with preparedness and strength.</p>
    <p>Welcome to our Climate Change Resilience Program where we're building a future that's not just sustainable but regenerative. Our comprehensive approach empowers communities to adapt to the challenges of climate change promoting environmental stewardship food security and climate-resilient agriculture practices.</p>
    <p>But that's not all. We invite you to explore our program in more depth by reviewing our scope of work which outlines our specific objectives strategies and activities in the areas of:</p>
    <p><b>Environmental Conservation</b></p>
    <p>We are committed to protecting and restoring natural ecosystems preserving biodiversity and promoting eco-friendly practices. Our objectives include:</p>
    <p>Promoting sustainable land-use practices</p>
    <p>Supporting conservation efforts and protected areas</p>
    <p>Encouraging community engagement and education</p>
    <p>To achieve this we implement strategies such as:</p>
    <p>Reforestation and afforestation initiatives to restore degraded lands and promote carbon sequestration</p>
    <p>Protection of water sources and wetlands to maintain ecosystem services</p>
    <p>Promotion of eco-friendly practices through education and outreach programs</p>
    <p>Our activities include:</p>
    <p>Planting trees and restoring forests in collaboration with local communities</p>
    <p>Conducting conservation workshops and training for stakeholders</p>
    <p>Supporting local conservation groups and initiatives</p>
    <p>Climate Change Adaptation and Mitigation</p>
    <p>We recognize the urgent need to address climate change and its impacts on vulnerable communities. Our objectives include:</p>
    <p>Enhancing climate resilience and adaptive capacity</p>
    <p>Reducing greenhouse gas emissions and promoting renewable energy</p>
    <p>Supporting climate-smart agriculture and livelihoods</p>
    <p>To achieve this we implement strategies such as:</p>
    <p>Implementing climate-resilient agriculture practices and agroforestry systems</p>
    <p>Supporting climate-smart agriculture and livestock production through research and extension services</p>
    <p>Enhancing disaster preparedness and response through early warning systems and emergency planning</p>
    <p>Our activities include:</p>
    <p>Conducting climate risk assessments and vulnerability mapping</p>
    <p>Implementing climate-resilient agriculture practices and agroforestry systems</p>
    <p>Supporting climate change research and advocacy through partnerships and collaborations</p>
    <p><b>Sustainable Agriculture</b></p>
    <p>We are dedicated to promoting sustainable agriculture practices that prioritize social equity environmental stewardship and economic viability. Our objectives include:</p>
    <p>Promoting agroforestry and permaculture practices for diversified and resilient farming systems</p>
    <p>Supporting organic farming and crop diversification for improved nutrition and income</p>
    <p>Improving access to climate-resilient seeds and technologies for smallholder farmers</p>
    <p>To achieve this we implement strategies such as:</p>
    <p>Promoting agroforestry and permaculture practices through training and demonstration programs</p>
    <p>Supporting organic farming and crop diversification through market access and value chain development</p>
    <p>Improving access to climate-resilient seeds and technologies through partnerships with research institutions and private sector companies</p>
    <p>Our activities include:</p>
    <p>Conducting sustainable agriculture training and workshops for farmers and extension agents</p>
    <p>Supporting sustainable agriculture projects and initiatives through grants and technical assistance</p>
    <p>Promoting sustainable agriculture policies and practices through advocacy and policy engagement</p>
    <p><b>Food Security</b></p>
    <p>We are committed to ensuring food security for all prioritizing the needs of vulnerable communities. Our objectives include:</p>
    <p>Enhancing agricultural productivity and yields through sustainable practices</p>
    <p>Supporting food storage and preservation techniques to reduce post-harvest losses</p>
    <p>Promoting nutrition education and awareness to improve dietary diversity and health outcomes</p>
    <p>To achieve this we implement strategies such as:</p>
    <p>Enhancing agricultural productivity and yields through conservation agriculture and agroforestry practices</p>
    <p>Supporting food storage and preservation techniques through training and provision of storage facilities</p>
    <p>Promoting nutrition education and awareness through school programs and community outreach</p>
    <p>Our activities include:</p>
    <p>Conducting food security assessments and research to identify areas of need</p>
    <p>Supporting food storage and preservation initiatives through grants and technical assistance</p>
    <p>Promoting nutrition education and awareness campaigns through partnerships with health organizations and local authorities</p>
    <p>Together let's build a future that's capable of withstanding the challenges of climate change and fostering a healthier planet for generations to come. Join us in supporting communities to thrive in the face of climate change. Let's work together towards a resilient future!</p>

    </>) },
    { servicesImg: Per04,text: 'Youth Development Program', content: (<> <h1>Youth Development Program</h1>
    <p>Unlocking the Potential of Young Leaders</p>

<p>Our Youth Development Program is dedicated to empowering young people to become active leaders, entrepreneurs, and change-makers in their communities. We focus on:

</p>

<p>i.	Leadership Development</p>

<p>- Building self-confidence and self-esteem</p>
<p>- Developing leadership and communication skills</p>
<p>- Encouraging community engagement and participation</p>

<p>ii.	Entrepreneurship</p>

<p>- Providing access to business training and mentorship</p>
<p>- Supporting innovative ideas and start-ups</p>
<p>- Fostering economic independence and financial literacy</p>

<p>iii.	Education</p>

<p>- Supporting access to quality education and scholarships</p>
<p>- Promoting literacy and numeracy skills</p>
<p>- Encouraging continuous learning and skill development</p></>) },
    {servicesImg: Per05, text: 'The Community Health Program', content: (<><h1>The Community Health Program</h1>
   <p>Welcome to our Community Health Program! We envision a world where every individual has access to quality healthcare and lives a life of dignity and well-being. We believe that every person deserves to live a healthy and fulfilling life, free from the burdens of preventable illnesses and stigma. Our program aims to address the critical health needs of our community, particularly among vulnerable populations such as women, girls, and marginalized groups. We provide education, resources, and support to empower individuals to take control of their health and make informed decisions about their bodies and lives. Guided by the principles of community empowerment, social justice, and human rights, we work in partnership with local communities to address the critical health needs of vulnerable populations. Through our comprehensive approach, we empower individuals to take control of their health and make informed decisions about their bodies and lives.</p>
    <p>We focus on three critical areas:</p>
    <p><b>Menstrual Hygiene</b></p>
    <p>We conduct community outreach and education sessions in schools and community centers to raise awareness about menstrual hygiene and promote healthy practices. We provide sanitary products and materials for menstrual hygiene management, such as reusable pads. We support the construction of gender-separated latrines and washing facilities in schools and communities to ensure privacy and dignity. We engage with local leaders and stakeholders to address stigma and taboo surrounding menstruation and promote community-led initiatives. We develop and distribute educational materials and resources on menstrual hygiene management, such as brochures, posters, and videos.</p>
    <p><b>Sexual Reproductive Health</b></p>
    <p>We provide comprehensive sexual and reproductive health services, including contraception and family planning, through mobile clinics and community health centers. We conduct community outreach and education sessions to raise awareness about sexual and reproductive health and rights. We support the development of sexual and reproductive health policies and programs that prioritize community needs and voices. We engage with local leaders and stakeholders to address gender-based violence and coercion and promote community-led initiatives. We provide educational materials and resources on sexual and reproductive health, such as brochures, posters, and videos.</p>
    <p><b>HIV/AIDS</b></p>
    <p>We conduct community outreach and education sessions to raise awareness about HIV/AIDS and promote healthy practices. We provide HIV testing and counseling services through mobile clinics and community health centers. We support the development of HIV/AIDS policies and programs that prioritize community needs and voices. We engage with local leaders and stakeholders to address stigma and discrimination surrounding HIV/AIDS and promote community-led initiatives. We provide educational materials and resources on HIV/AIDS, such as brochures, posters, and videos.</p>
    <p>Join us in our mission to create a healthier, more compassionate world where everyone has access to the healthcare and support they need to thrive.</p>
</>) },
    { servicesImg: Per06,text: 'The Education Support Program', content: (<><h1>The Education Support Program</h1>
   <p>Education is the key to unlocking the potential of individuals, communities, and societies as a whole. Yet, many marginalized children and youth lack access to quality education, hindering their ability to reach their full potential and break the cycle of poverty.</p>
    <p>Our Education Support Program aims to bridge this gap by providing comprehensive support to vulnerable students, empowering them to succeed in school and beyond. Our program addresses the unique challenges faced by these students, including financial barriers, lack of resources, and limited access to opportunities.</p>
    <p>To learn more about the specific activities, tasks, and goals of our Education Support Program, please refer to the scope of work below. Our scope of work outlines the detailed plans and strategies for implementing this vital program, and we invite you to explore it in detail.</p>
    <p>Our Scope of Work includes</p>
    <p><b>1. Cost Barrier Removal</b></p>
    <p><b>a. Identify eligible students:</b></p>
    <p>• Conduct door-to-door surveys in marginalized communities</p>
    <p>• Collaborate with local schools to identify students in need</p>
    <p>• Review academic records and financial documents to determine eligibility</p>
    <p><b>b. Assess family income and financial needs:</b></p>
    <p>• Conduct home visits to assess living conditions and financial stability</p>
    <p>• Review financial documents, such as income statements and expenses</p>
    <p>• Assess the number of dependents and financial responsibilities</p>
    <p><b>c. Provide financial assistance:</b></p>
    <p>• Disburse funds directly to schools or vendors for school fees, materials, and uniforms</p>
    <p>• Establish a payment structure with families, such as matching funds or incremental payments</p>
    <p>• Monitor student progress and adjust support as needed</p>
    <p><b>2. Equal Access to Education</b></p>
    <p><b>a. Conduct community outreach:</b></p>
    <p>• Organize community events, such as town halls and information sessions</p>
    <p>• Engage with local leaders, religious groups, and community organizations</p>
    <p>• Raise awareness about the program through social media, flyers, and posters</p>
    <p><b>b. Provide support for children with disabilities:</b></p>
    <p>• Collaborate with special needs organizations to provide additional resources and accommodations</p>
    <p>• Provide tailored support, such as sign language interpreters or wheelchair accessibility</p>
    <p>• Ensure inclusive learning environments that accommodate different abilities</p>
    <p><b>c. Offer bridging programs:</b></p>
    <p>• Develop tailored curricula and activities to help students catch up with their peers</p>
    <p>• Provide additional academic support, such as tutoring and mentoring</p>
    <p>• Help students develop critical thinking, problem-solving, and creativity skills</p>
    <p><b>3. Community School Partnerships</b></p>
    <p><b>a. Identify partner schools:</b></p>
    <p>• Research local schools and their needs, such as resource gaps and infrastructure challenges</p>
    <p>• Conduct site visits to assess school conditions and meet with administrators</p>
    <p><b>b. Establish relationships with school leaders, school administrators and teachers:</b></p>
    <p>• Collaborate on student identification and support</p>
    <p>• Provide training and resources for teachers, such as workshops and coaching</p>
    <p>• Foster open communication and feedback channels</p>
    <p><b>c. Collaborate on student selection:</b></p>
    <p>• Develop criteria for student selection, such as academic potential and financial need</p>
    <p>• Review academic records and financial documents to select students</p>
    <p>• Ensure diverse selection of students from different backgrounds and abilities</p>
    <p><b>4. Student Retention</b></p>
    <p><b>a. Identify at-risk students:</b></p>
    <p>• Monitor academic performance, attendance, and behavior</p>
    <p>• Conduct regular assessments and evaluations to identify areas of concern</p>
    <p>• Engage with teachers and school administrators to identify students in need</p>
    <p><b>b. Develop individualized support plans:</b></p>
    <p>• Set academic and personal goals with students and parents</p>
    <p>• Provide tailored support and resources, such as tutoring and mentoring</p>
    <p>• Regularly review and adjust plans to ensure progress</p>
    <p><b>c. Provide mentorship and counseling:</b></p>
    <p>• Assign trained mentors and counselors to students</p>
    <p>• Conduct regular sessions and check-ins to address personal and academic challenges</p>
    <p>• Encourage students to develop self-advocacy and problem-solving skills</p>
    <p><b>5. Academic Support</b></p>
    <p><b>a. Provide additional academic support:</b></p>
    <p>• Provide additional resources and materials, such as textbooks and online resources</p>
    <p>• Encourage critical thinking and problem-solving skills through project-based learning</p>
    <p><b>b. Offer extracurricular activities:</b></p>
    <p>• Develop programs for arts, sports, and clubs that cater to different interests</p>
    <p>• Encourage participation and engagement through incentives and recognition</p>
    <p>• Foster teamwork and social skills through group activities and projects</p>
    <p><b>c. Monitor student progress:</b></p>
    <p>• Regularly review academic performance and progress towards goals</p>
    <p>• Assess student engagement and satisfaction with the program</p>
    <p>• Adjust support and resources as needed to ensure student success</p>
    <p>By supporting our Education Support Program, you will be helping to create a brighter future for marginalized students and communities. Join us in our mission to empower vulnerable students and shape a better future for all.</p></>) },
    { servicesImg: Per07, text: 'The Community Engagement and Mobilization Program', content: (<><h1>The Community Engagement and Mobilization Program</h1>
             
    <p>Empowering Communities, Building a Better Future</p>
    
    <p>Our Community Engagement and Mobilization Program is designed to mobilize and engage community members in the development process, promoting community participation, inclusivity, and social cohesion. We focus on:
    
    </p>
    
    
    <p>i.	Community Participation</p>
    
    <p>- Encouraging community members to take an active role in development initiatives</p>
    <p>-  Providing platforms for community members to contribute to decision-making processes</p>
    <p>- Supporting community-led projects and initiatives</p>
    
    <p> ii.	Inclusivity</p>
    
    <p>-  Promoting equal opportunities for all community members, regardless of gender, age, or ability</p>
    <p>-  Encouraging diversity and inclusion in community engagement activities</p>
    <p>-  Supporting marginalized groups and ensuring their voices are heard</p>
    
    <p> iii.	Social Cohesion</p>
    
    <p>-  Fostering a sense of community and belonging among community members</p>
    <p>- Promoting respect, understanding, and empathy among community members</p>
    <p>-  Encouraging collective action and collaboration</p></>) },
  
  ];

  const handleFeatureClick = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const handleCloseModal = (e) => {
    e.stopPropagation();
    setShowModal(false);
    setModalContent(null);
  };

  return (
    <div className="services" id="blog">
       <div className="wrapper">
          <Title title="PROGRAM" />

          <Row>{features.map((feature, index) => (
       
<Col md={12} lg={4}>
        <Feature
          key={`PROGRAM`+index}
          text={feature.text}
          avatar={feature.servicesImg}
          onClick={() => handleFeatureClick(feature.content)}
        />
        </Col>
       
      ))} </Row>
     
      <Modal  isOpen={showModal}
        onRequestClose={handleCloseModal}
         >
        {modalContent}
      </Modal>
    </div>
    </div>
  );
};



export default Services;
