import React from "react";
import { Row, Col } from "react-flexbox-grid";
// SCSS
import "../mission-vision/hero.scss";
import Title from "../ui-components/title/title";
//Assets
import HeroImage from '../../assets/hero/Donatecampaigns/back.jpg';
import HeroImage2 from '../../assets/hero/Donatecampaigns/snakes_attack.png';
import HeroImage3 from '../../assets/hero/Donatecampaigns/water_supply.jpg';
import HeroImage4 from '../../assets/hero/Donatecampaigns/water_collection.png';
import HeroImage5 from '../../assets/hero/Donatecampaigns/young_child_risk.png';
import HeroImage6 from '../../assets/hero/Donatecampaigns/school.png';
import QRcode from '../../assets/contact/qrcode.png';
//import Donatecampaigns from '../payments/campaignlink';
//import DonateButton from '../payments/DonateButton';
//Components
const Campaigns = () => {
  
  return (
  <div id="campaigns">
  <div className="wrapper">
    <Title title="OUR CURRENT CAMPAIGNS" />
    <div className="hero-container">
      <div className="hero-overlay">
        <h3 className="hero-title">
          Clean Water for Bajjo Lumansi: A Lifeline for 1,500 Ugandans
        </h3>
      </div>
      <img src={HeroImage} alt="A Lifeline for 1,500 Ugandans" className="hero-background" />     
    </div>
    </div>
    <div className="wrapper">
    <Row>
      <Col md={12} lg={12}>
        <div className="hero-info">
          <p className="font16 martop30">
            <b className="weight800">The Problem:</b>
          </p>
          <p className="font14">
            In rural Uganda, the Bajjo Lumansi Community in Nyimbwa subcounty in Luwero district faces a dire water crisis. The current water source, a dilapidated well, poses significant risks:
          </p>
        </div>
      </Col>
      </Row>
      <Row>      
      <Col md={12} lg={2.5} xs={6}>
        <div className="hero-info">
          <div className="image-container">
            <img src={HeroImage2} alt="Attracts dangerous" className="hero-image" />
            <div className="overlay">
              <h4 className="Problemtext">Attracts dangerous snakes, putting users in harm's way</h4>
            </div>
          </div>
        </div>
      </Col>
      <Col md={12} lg={2.5} xs={6}>
        <div className="hero-info">
          <div className="image-container">
            <img src={HeroImage3} alt="Contaminated by brick" className="hero-image" />
            <div className="overlay">
              <h4 className="Problemtext">Contaminated by brick layers, compromising water quality</h4>
            </div>
          </div>
        </div>
      </Col>
      <Col md={12} lg={2.5} xs={6}>
        <div className="hero-info">
          <div className="image-container">
            <img src={HeroImage4} alt="Women and girls bear" className="hero-image" />
            <div className="overlay">
              <h4 className="Problemtext">Women and girls bear the brunt of distant water collection, wasting hours daily</h4>
            </div>
          </div>
        </div>
      </Col>
      <Col md={12} lg={2.5} xs={6}>
        <div className="hero-info">
          <div className="image-container">
            <img src={HeroImage5} alt="Open well poses" className="hero-image" />
            <div className="overlay">
              <h4 className="Problemtext">Open well poses a drowning risk to young children</h4>
            </div>
          </div>
        </div>
      </Col>
      <Col md={12} lg={2.5} xs={6}>
        <div className="hero-info">
          <div className="image-container">
            <img src={HeroImage6} alt="Children miss school" className="hero-image" />
            <div className="overlay">
              <h4 className="Problemtext">Children miss school due to water fetching duties, hindering their education</h4>
            </div>
          </div>
        </div>
      </Col>
        </Row>
        <Row style={{backgroundColor: '#dfe6e5'}}  className="shadow martop30">
        <Col md={12} lg={6} className="paddingLeft4">
        <div className="hero-info martop30">  
        <p className="font16">
            <b className="weight800" >The Solution:</b> </p>
            <p className="font14">We aim to raise <b className="font23" > $6579 </b> to drill a solar-powered deep well and construct a 10,000-liter storage tank, providing safe and clean water for 1,500 residents.</p>

            <p className="font16"><b className="weight800" >The Impact:</b> </p>

            <p className="font14">- Improved health and wellbeing for the community</p>
            <p className="font14">- Increased school attendance and productivity</p>
            <p className="font14">- Reduced healthcare costs and burden on households </p>
            <p className="font14">- Enhanced economic opportunities and development </p>
            <p className="font14">- Saved lives by preventing drowning and waterborne diseases </p>
            </div>

            <p className="font16"><b className="weight800" >Join Us:</b> </p>
            <p className="font14">Support our Clean Water and Sanitation Program to transform the lives of the Bajjo Lumansi Community. Every contribution counts!</p>

            <p className="font14">Donate Now and help bring clean water, safety, and hope to this rural Ugandan community!</p>
            </Col>     
            <Col md={12} lg={6} className="paddingLeft4" >         

 <div className="custom-frame-wrapper martop30">
  <div className="custom-frame-inner-wrapper">
    <div className="custom-frame-padding">
      <a rel="prettyPhoto" href="https://www.paypal.com/donate?hosted_button_id=TH3ADD845PMZ4">
        <img width="147" height="47" src="https://www.paypal.com/en_US/i/btn/btn_donateCC_LG.gif" className="attachment-large size-large entered lazyloaded" alt="attachment-large size-large entered lazyloaded"
          role="button" /></a></div></div>          
 <p className="font16"><b className="weight800" >Follow the QR code:</b> </p>
<img src={QRcode} alt="QR code for donation" className="qrcodeImg"   />

          </div>
         
         
        </Col>
      </Row>
    </div>
   
  </div>
);
};
export default Campaigns;
