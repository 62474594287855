import React from "react";
import { Row, Col } from "react-flexbox-grid";
import "./contactSocial.scss";


//import FacebookIcon from "../../../assets/contact/facebook.svg";
//import TwitterIcons from "../../../assets/contact/twitter.svg";
//import DribbleIcon from "../../../assets/contact/dribble.svg";

import { FaFacebookF , FaLinkedinIn } from "react-icons/fa";
const contactSocial = () => (
  <Row>
    <Col xs={12}>
      <Row center="xs">
        <Col xs={4} lg={1} className="contact__social">
          <a href="https://www.facebook.com/people/Luwero-Community-Initiative-Uganda/61560334284090"  rel="noopener noreferrer" target="_blank">
          
   <FaFacebookF size={42} />
     </a>
        </Col>
        <Col xs={4} lg={1} className="contact__social">
        <a href="https://www.linkedin.com/in/luwero-community-initiative-uganda-0b57b3317/"  rel="noopener noreferrer" target="_blank">
        <FaLinkedinIn size={42} />
        </a>
        </Col>
        
      </Row>
    </Col>
  </Row>
);

export default contactSocial;
