import React from 'react';
import Modal from 'react-modal';
//Modal.setAppElement('#root'); // Make sure to set the app element

const BlogModal = ({ isOpen, onRequestClose, children, title}) => {
  if (!isOpen) return null;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Modal"     
          style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex:'99'
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: '20px',          
          marginTop: '2%',
          height: '60%',
          width: '98%',
          overflowY:'scroll',
          inset: '53% auto auto 50%',
          border:'2px solid #67b41e'
          
        }
      }}
    >
       <button className='button shadow right' onClick={onRequestClose}>X</button>
       <div style={ {marginTop: '55px'}}>  {title}</div>
      <div style={ {marginTop: '20px'}}>  {children}</div>
    
    </Modal>
  );
};

export default BlogModal;
