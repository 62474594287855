import React from "react";
//import { useTranslation } from 'react-i18next';
import { Row, Col } from "react-flexbox-grid";
import "./about.scss";
// Components
//import TeamBox from './teamBox';
import TeamInfo from "./teamInfo";
import Title from "../ui-components/title/title";
// Assets
import aboutus from "../../assets/hero/team_more.jpg";
//import aboutus2 from "../../assets/hero/aboutus.jpg";
//import Person02 from "../../assets/about/person02.jpg";

const About = () => {
  //const { t } = useTranslation();

  return (
  <div id="about">
    <div className="wrapper">
      <Title title="ABOUT US" />
      <Row>
      <Col md={12} lg={6}>
      <h3 className="weight800 ">Who We Are</h3>
      <p className="font14">Luwero community initiative (LCI) is a not for profit organization dedicated to empowering marginalized communities through sustainable development programs. We believe in the potential of local communities to drive positive change and improve their own lives.</p>
      {/*<img src={aboutus2} alt="about us background" height={300} width={"100%"}/>*/}
      </Col>
      <Col md={12} lg={6}> 
        <h3 className="weight800">Why We Matter</h3>
        <p className="font14">By supporting LCI, you are investing in the potential of marginalized communities to transform their own lives and create lasting change. Your funding will enable us to:</p>
        <p className="font14">- Empower local leaders and residents to drive their own development</p>
        <p className="font14">- Deliver innovative programs and services that address the most pressing needs of marginalized communities</p>
        <p className="font14">- Build partnerships and collaborations that amplify our impact and reach</p>
</Col>
      </Row>
      <Row className="">
      <Col md={12} lg={6}>
      <h3 className="weight800">Imperative of LCI</h3>
      <p className="font14">it's a deep understanding of the community’s needs and a commitment to locally-led solutions, Balungi established LCI as a registered Not-for profit organisation in Uganda (Reg. No. <b>80034329518799</b>) that would tackle the most pressing issues:</p>

<p className="font14">•	Limited access to clean water</p>
<p className="font14">•	Inadequate sanitation infrastructure</p>
<p className="font14">•	Disempowered women</p>
<p className="font14">•	The looming threat of climate change</p>

<p className="font14">Our story is one of hope, resilience and determination. We stand firm in our commitment to our mission and vision, driven by an unwavering passion to empower marginalized communities and create a better future for all. We are dedicated to building resilient, sustainable, and empowered communities, where everyone can live with dignity, purpose and hope.</p>

        </Col>
        <Col md={12} lg={6}>
      <h3 className="weight800 ">History of LCI</h3>
      <p className="font14">Luwero community initiative (LCI) was founded by Balungi Miria in 2021 driven by her passion to address the pressing challenges facing the communities of Nyimbwa, Makulubita and Katikamu sub counties in Luwero district.</p>

      <p className="font14">“As a resident of this community, I witnessed the struggles of my neighbours and friends. I knew I had to act, and LCI was born from that desire to make a difference”, Balungi Miria</p>

      <p className="font14">Balungi’s journey in community development began in 2014, working with various organizations, including Uganda Youth Network, Public Policy Institute, Action Aid International, and Network for Active Citizens. Her experience in community mobilization, advocacy, and project management laid the foundation for LCI’s success</p>
      </Col>
       
      </Row>
     
      <Row>
        <Col md={12} lg={8}>
        <div >
                <img src={aboutus} alt="about us background"/>
              </div>
        
          {/*<TeamBox avatar={Person01} name="KULYANI Ronald" job="CHAIRPERSON" />
        </Col>
        <Col md={12} lg={4}>
  <TeamBox avatar={Person02} name="NAKASUJJA Hellen" job="VICE CHAIRPERSON" />*/}
        </Col>
        <Col md={12} lg={4}>
          <TeamInfo />
        </Col>
      </Row>
    </div>
  </div>
);
};
export default About;
