import React, { useState ,useEffect } from 'react';
import "./blogBox.scss";
import BlogModal from '../services/Modal';

const Blog = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModaltitle] = useState(null);
  const handleTeamPersonClick = (title,content) => {    
    setModaltitle(title);
    setModalContent(content);
    setShowModal(true);
  };
  
  const handleCloseModal = (e) => {   
    e.stopPropagation();
    setShowModal(false);
    setModalContent(null);   
  };

  useEffect(() => {
   // console.log('Modal visibility changed:', showModal);
  }, [showModal]);
  return (
    <div className="blog__box" onClick={() => handleTeamPersonClick(props.article.title,props.article.description)}>
      <div className="blog__image">
        <img src={props.article.image} alt="blog story"   />
        <div className="blog__hover flex-center">
          <h4 className="font30 weight800">READ MORE</h4>
        </div>
      </div>
      <div className="blog__info">
        <h4 className="font15 weight800">{props.article.title}</h4>
      {/* <p className="font12 weight500 padding10">{props.article.description}</p> */}
       <p className="font12 weight500">{props.article.date}</p>
      </div>
      <BlogModal  isOpen={showModal}
       onRequestClose={handleCloseModal}
         ><h1>{modalTitle}</h1>
          {modalContent?.split('. ').map((sentence, index, array) => (
      <p key={index}>{sentence}{index < array.length - 1 ? '.' : ''}</p>
    ))}
       
      </BlogModal>
    </div>
    
  );
 };

export default Blog;
