import React from "react";
import { Row, Col } from "react-flexbox-grid";
import { Link } from "react-scroll";
import "./footer.scss";

import LogoImg from '../../assets/navbar/footerLogo.png';
import Arrow from '../../assets/footer/arrow.svg';
let currentYear = new Date().getFullYear();

const partnerBox = () => (
  
  <div className="footer">
    <div className="wrapper">
      <Row>
        <Col xs={12} sm={8} md={8}>
          <div className="footer-box">
            <img src={LogoImg} height={90} alt="logo" />         
            <p className="font16">Registration No: 80034329518799 <br></br>
            © {currentYear} - Luwero Community Initiative    <a target="_blank" className="font15 weight500 flex-s-between" rel="noopener noreferrer" href="https://s10.my-control-panel.com/roundcube/">Login</a></p>
          </div>
          
         
        </Col>
        <Col xs={12} sm={4} md={4}>
          <Link to="hero" spy={true} smooth={true} offset={0} duration={500}>
            <div className="footer-box back-to-top">
              <p>BACK TO TOP</p>
              <img src={Arrow} alt="arrow" />
            </div>
          </Link>
         
        </Col>
      </Row>
    </div>
  </div>
);
export default partnerBox;
