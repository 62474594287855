import React from 'react';
import "./hero.scss";
const Feature = ({ avatar, text, onClick }) => {
  return (
    <div className="team__box flex-center" onClick={onClick}>
       <img src={avatar} alt="services" />
       <div className="team__box-info">        
       <h3 className="weight800">{text}</h3>
       <h4>Read More</h4>
       </div>
    </div>    
  );
};

export default Feature;