import React from "react";
import { Row, Col } from "react-flexbox-grid";
// SCSS
import "./hero.scss";
//Assets
import HeroImage from '../../assets/hero/work.jpg';
import HeroImage2 from '../../assets/hero/mission1.jpg';
import HeroImage3 from '../../assets/hero/work2.jpg';
//Components
const missionVision = () => (
  <div className="mission" id="hero">
    <div className="wrapper">
      <Row>
      <Col md={12} lg={6}>
          <div className="hero-image">
            <img src={HeroImage} alt="hero" />
          </div>
        </Col>
        <Col md={12} lg={6}>
          <div className="hero-info">           
            <h1 className="weight800 font30">
            Mission
            </h1>
            <p className="font14">
            Our mission is to empower the local community through innovative and sustainable WASH solutions, amplifying women’s voices and fostering a climate resilient community.
            </p>
           
          </div>
        </Col>
       
      </Row>
    </div>
    <div className="wrapper">
      <Row>
      
        <Col md={12} lg={6}>
          <div className="hero-info">           
            <h1 className="weight800 font30">
            Vision
            </h1>
            <p className="font14">
            Our vision is a thriving and resilient community where everyone lives in harmony with each other and the environment, enjoying social and economic wellbeing.
            </p>
           
          </div>
        </Col>
        <Col md={12} lg={6}>
          <div className="hero-image">
            <img src={HeroImage2} alt="hero" />
          </div>
        </Col>
      </Row>
    </div>
    <div className="wrapper">
      <Row>
      <Col md={12} lg={6}>
          <div className="hero-image">
            <img src={HeroImage3} alt="hero" />
          </div>
        </Col>
        <Col md={12} lg={6}>
          <div className="hero-info">           
            <h1 className="weight800 font30">
            Organisational Structure
            </h1>
            <p className="font14">
            LCI is governed by a Board of Directors, responsible for strategic direction and oversight. Our Executive Director leads the management team, which includes program and project staff, community engagement officers, and administrative support. We also work with a network of community volunteers, partners, and collaborators to deliver our programs and services.
            </p>
           
          </div>
        </Col>
        
      </Row>
    </div>
  </div>
);

export default missionVision;
