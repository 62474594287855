import React from "react";
import { Row, Col } from "react-flexbox-grid";
import "./contact.scss";
import * as emailjs from "emailjs-com";
import Title from "../ui-components/title/title";

import ContactSocial from './contactInfo/contactSocial';
import Modal from '../contact-modal/Modal';

import ContactBackground from '../../assets/contact/misin2.jpg';

//import PayPal from '../payments/paypal';

//import DonateCampaign from '../payments/campaignold';
import { BsFillPinMapFill ,BsFillTelephoneFill,BsEnvelopeAtFill   } from "react-icons/bs";
//import InnerHTML from 'dangerously-set-html-content';
class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      sending: false,
      successModal: false,
      errorModal: false,
      checkout: false,
    };
  }

  inputHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (e) => {
    var self = this;
    this.setState({ sending: true });
    e.preventDefault();

    var template_params = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
    };
    // YOUR EMAIL.JS API KEY IN FORMAT user_xxxxxxxxxxxxxxxxxx
    let API_KEY = "";

    // YOUR EMAIL.JS TEMPLATE ID
    let TEMPLATE_ID = "";




    emailjs.send("default_service", TEMPLATE_ID, template_params, API_KEY).then(
      function (response) {
        if (response.status === 200) {
          self.showSuccessModal();
        } else {
          self.showErrorModal();
        }
      },
      function (error) {
        self.showErrorModal();
      }
    );
  };

  // SUCCESS MODAL
  showSuccessModal = () => {
    this.setState({ successModal: true, sending: false });
    this.resetForm();
  };
  // ERROR MODAL
  showErrorModal = () => {
    this.setState({ errorModal: true, sending: false });
    this.resetForm();
  };
  // RESET CONTACT FORM
  resetForm() {
    this.setState({ name: "", email: "", message: "" });
  }
  // CLOSE ALL MODALS
  closeModal = () => {
    this.setState({ successModal: false, errorModal: false });
  };

  resetForm = () => {
    this.setState({ name: "", email: "", message: "" });
  };

  render() {
    /*let submitButtonRender = (
      <div className="small__button">
        <button aria-label="send message" type="submit" value="Send Message">
          Send Message
        </button>
      </div>
    );*/
    //if (this.state.sending) {
     /* submitButtonRender = (
        <div className="small__button sending-btn">
          <div className="flex-center">
            <div className="sbl-circ"></div>
          </div>
        </div>
      );*/
  //  }
    let modalRender = null;
    if (this.state.successModal) {
      modalRender = <Modal closeModal={this.closeModal} status="success" />;
    } else if (this.state.errorModal) {
      modalRender = <Modal closeModal={this.closeModal} status="error" />;
    }
    /*const htmlContent = 
        `<div id="donate-button-container">
        <div id="donate-button"></div>
        <script src="https://www.paypalobjects.com/donate/sdk/donate-sdk.js" charset="UTF-8"></script>
        <script>
        PayPal.Donation.Button({
        env:'sandbox',
        hosted_button_id:'EEQR69TYKB6N4',
        image: {
        src:'https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif',
        alt:'Donate with PayPal button',
        title:'PayPal - The safer, easier way to pay online!',
        }
        }).render('#donate-button');
        </script>
        </div>`;*/
 
    return (
      <div id="contact">
        {modalRender}
        <div className="wrapper">
          <Title title="CONTACT US." />  
          <Row className="">
            <Col md={12} lg={7}>     
          <h3 className="weight800">Donation</h3>
          <p className="font14">Empower marginalized communities</p>
          <p className="font14">Your donation can help us unlock the potential of marginalized communities and create a more just and equitable society. Every contribution, big or small, counts!</p>
          <h3 className="weight800">Ways to Donate</h3>
<p className="font14"><b>Online donation:</b> make a secure online donation through our website</p>
<p className="font14"><b>Bank transfer: </b> transfer funds to our bank account</p>
<p className="font14"><b>Cheque donation: </b>send a cheque payable to Luwero Community Initiative  (LCI)</p>
<p className="font14"><b>Recurring Donation: </b> set up a monthly or quarterly donation to support our ongoing programs</p>
</Col>   
<Col md={12} lg={5}>
<h3 className="weight800">Contact Info</h3> 
<Row>
        <Col xs={12} lg={12} md={12} className="contact__info">  
            
    <div className="flex-row-center">    
    <BsFillPinMapFill />  
   <p className="font14 paddingLeft">UGANDA, CENTRAL, LUWERO, NYIMBWA WAVE 3, BAJJO, BAJJO-LUMANSI</p>
    
  </div>
         
        </Col>
        <Col xs={12} lg={12} md={12} className="contact__info">
        
        <div className="flex-row-center">     
      <BsFillTelephoneFill  />      
    
      <p className="font14 paddingLeft">+256-39-3242145</p>    
  
    </div>
         
        </Col>
        <Col xs={12} lg={12} md={12} className="contact__info">
        
        <div className="flex-row-center">      
    <BsEnvelopeAtFill   />   
    
      <p className="font14 paddingLeft">info@lciuganda.org</p>    
    </div>
    
         
        </Col>
      </Row>
</Col>
</Row>
          <Row >
            {/*<Col md={12} lg={12}>
              <div className="marbottom martop30">          
           

           </div>
            {/* <InnerHTML html={htmlContent} /> 
            {this.state.checkout ? (
                           
        <PayPal />
      ) : (
        
        <button className="marbottom"
          onClick={() => {
            this.setState({ checkout: true});
           // setCheckOut(true);
          }}
        >
          
          Donate
        </button>
      )}*/}
              {/* <form id="contact-form" onSubmit={this.handleSubmit}>
                <h4 className="font30 weight800 padding30">Send Us Message.</h4>
                <input type="text" placeholder="Name" required name="name" value={this.state.name} onChange={this.inputHandler} />
                <input type="email" placeholder="Email" required name="email" value={this.state.email} onChange={this.inputHandler} />
                <textarea
                  rows="6"
                  cols="50"
                  placeholder="Message..."
                  required
                  name="message"
                  value={this.state.message}
                  onChange={this.inputHandler}
                ></textarea>
                {submitButtonRender}
    </form> 
            </Col>*/}
            <Col md={12} lg={12}>
              <div className="flex-center">
                <img src={ContactBackground} alt="contact background" />
              </div>
            </Col>
          </Row>         
          <ContactSocial />
        </div>
      </div>
    );
  }
};

export default Contact;
