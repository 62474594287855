import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; // Import Swiper styles
import 'swiper/components/navigation/navigation.scss'; // Import Navigation module styles
import 'swiper/components/pagination/pagination.scss'; // Import Pagination module styles
import "./blog.scss"; // Your custom styles

// Import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper';
// Assets
import Preview01 from "../../assets/blog/story01/team-memb1.jpg";
import Preview02 from "../../assets/blog/story02/team-memb2.jpg";
import Preview03 from "../../assets/blog/story03/team-memb3.jpg";
import Preview04 from "../../assets/blog/story04/team-memb4.jpg";
import Preview05 from "../../assets/blog/story05/team-memb5.jpg";
import Preview06 from "../../assets/blog/story06/team-memb6.jpg";
import Preview07 from "../../assets/blog/story07/team-memb7.jpg";
import Preview08 from "../../assets/blog/story08/team-memb8.jpg";
import Preview09 from "../../assets/blog/story09/team-memb9.jpg";
import Preview10 from "../../assets/blog/story10/team-memb10.jpg";
import Preview11 from "../../assets/blog/story11/team-memb11.jpg";
// Components
import Title from "../ui-components/title/title";
import BlogBox from "./blogBox";
// Install Swiper modules
SwiperCore.use([Navigation, Pagination]);
class Blog extends React.Component {
  state = {
    // LIST ARRAY OF BLOG STORIES
    stories: [
      {
        image: Preview01,
        id: "1",
        title: "KULYANI Ronald",
        description: "He brings a wealth of technical expertise and leadership experience to our organization. With a Bachelor of Engineering in Civil Engineering, and Diplomas in Biomedical Engineering and Refrigeration Engineering, Ronald has a strong academic background in engineering and technology.Throughout his career, Ronald has demonstrated exceptional leadership skills, guiding teams and organizations towards achieving their goals and objectives. His extensive experience in engineering and project management has equipped him with a unique ability to analyse complex problems, develop innovative solutions, and drive results-oriented initiatives.As Chairperson of the Board, Ronald provides strategic guidance and oversight to LCI, ensuring that our programs and initiatives align with our mission and vision. His technical expertise, leadership acumen, and passion for social impact make him an invaluable asset to our organization.Under Ronald's leadership, LCI is poised to achieve even greater heights, driving meaningful change and sustainable development in the communities we serve. His commitment to excellence, transparency, and accountability ensures that our organization remains focused on delivering high-quality programs and services that exceed the expectations of our stakeholders.",
        date: "CHAIRPERSON",
      },
      {
        image: Preview02,
        id: "2",
        title: "NAKASUJJA Hellen",
        description: "With a Bachelor's degree in Community Psychology and a proven track record of working with  focused on climate change and youth empowerment, Helen possesses a deep understanding of the complex social and environmental issues that LCI addresses. Her extensive experience in community engagement, program development, and partnership building makes her an invaluable asset to our organization. Under her guidance and leadership, we are confident that LCI will continue to grow and thrive, making a meaningful impact in the lives of marginalized communities.",
        date: "VICE CHAIRPERSON",
      },
      {
        image: Preview03,
        id: "3",
        title: "BALUNGI Miria",
        description: "She brings a unique combination of technical expertise, leadership acumen, and passion for social impact to LCI. With a degree in Information Systems and a wealth of knowledge in advocacy, governance, and Monitoring and Evaluation (M&E), Miria has a proven track record of driving success in various NGOs, including Uganda Youth Network, Public Policy Institute, Action Aid International, and Network for Active Citizens. Her extensive experience in strategic planning, program management, and stakeholder engagement makes her an exceptional leader. Under her guidance, LCI is poised to achieve its mission and make a meaningful difference in the lives of marginalized communities. With Miria at the helm, we are confident that LCI will continue to thrive and grow, thanks to her visionary leadership, technical expertise, and dedication to social change.",
        date: "SECRETARY AND EXECUTIVE DIRECTOR",
      },
      {
        image: Preview04,
        id: "4",
        title: "NUWAGIRA Gerald",
        description: "He brings a unique combination of legal expertise and environmental knowledge to LCI. With a Bachelor of Laws and extensive experience in environmental conservation and climate change, Gerald provides invaluable guidance on policy matters. His legal acumen and passion for sustainability ensure that LCI's initiatives are both effective and compliant with relevant regulations. Under his guidance, we are confident that LCI will continue to grow and make a meaningful impact in the lives of marginalized communities, while also contributing to a more sustainable future for all. ",
        date: "MEMBER",
      },
      {
        image: Preview05,
        id: "5",
        title: "MUNABA Geoffrey",
        description: "He brings a wealth of knowledge and expertise in Hydraulics and Water Resource Development to LCI. With a Bachelor of Science in Mechanical Engineering, Geoffrey has a strong technical background and a proven track record of successful implementation of projects in different agencies around East Africa. His extensive experience in risk management, water resources planning, design and implementation makes him an invaluable asset to our organization. As a member of our Board of Directors, Geoffrey's expertise and guidance will help shape LCI's WASH initiatives, ensuring that our programs are effective, sustainable, and impactful. With Geoffrey's expertise, we are confident that LCI will continue to make a meaningful difference in the lives of marginalized communities, improving their access to safe water, sanitation, and hygiene.",
        date: "MEMBER",
      },
      {
        image: Preview06,
        id: "6",
        title: "KANSIIME Angella",
        description: "She brings a wealth of accounting and financial expertise to LCI. With a Bachelor of Accounting degree and a strong track record in financial management, Angela provides invaluable oversight and guidance on financial transparency and accountability. Her proficiency in international languages, including French, enables her to effectively engage with global partners and stakeholders. As a valued member of our board, Angela's expertise and experience will help ensure responsible governance and strategic direction, supporting LCI's mission to make a positive impact in marginalized communities.",
        date: "MEMBER",
      },
      {
        image: Preview03,
        id: "7",
        title: "Balungi Miria",
        description: "Executive Director",
        date: "Executive Director",
      },
      {
        image: Preview07,
        id: "8",
        title: "NFULAGANI Peter JULIUS",
        description: "He brings a wealth of expertise in financial management and administration to our organization. With a Bachelor of Statistics and a Master of Economic Policy and Planning, Peter has a strong academic background in financial analysis and economic development. As a Certified Public Accountant (CPA), he has honed his skills in financial reporting, budgeting, and auditing.Peter's extensive experience working with reputable financial institutions, including DFCU Bank and Orient Bank, has equipped him with a deep understanding of financial markets, risk management, and compliance. His impressive track record in financial management has earned him a reputation for transparency, accountability, and fiscal responsibility.At LCI, Peter oversees all financial and administrative operations, ensuring that our programs are implemented with transparency, accountability, and maximum impact. His expertise in financial planning, budgeting, and reporting enables us to make informed decisions, optimize resources, and deliver results that exceed expectations.With Peter at the helm of our finance and administration team, we are confident that our funds are in capable hands, and our commitment to transparency and accountability is upheld.",
        date: "Head of Finance & Administration",
      },
      {
        image: Preview08,
        id: "9",
        title: "Nandawula Nubuwatti",
        description: "Programs Officer",
        date: "Programs Officer",
      },
      {
        image: Preview09,
        id: "10",
        title: "EGERU Michael",
        description: "Programs Officer",
        date: "Programs Officer",
      },
      {
        image: Preview10,
        id: "11",
        title: "KAJAMURE Edward",
        description: "Field Officer",
        date: "Field Officer",
      },
      {
        image: Preview11,
        id: "12",
        title: "NAKISEDE Caroline",
        description: "Administrative Assistant",
        date: "Administrative Assistant",
      },
    ],
  };

  render() {
    // BLOG STORIES RENDER
    let storiesRender = null;
    if (this.state.stories) {
      storiesRender = this.state.stories.map((story) => (
        <SwiperSlide key={story.id}>
        <BlogBox article={story} />
      </SwiperSlide>
      ));
    }
    // OPTIONS FOR BLOG SLIDER
    const params = {
      grabCursor: true,
      effect:'flip',
      slidesPerView: 1,
      spaceBetween: 10,
      loop: true,
     // Enable pagination with bullets
      breakpoints: {
        1200: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
      },
    };

    return (
      <div className="blog" id="blog">
        <div className="wrapper">
          <Title title="OUR TEAM" />
          <p className="font14">
          Together, we can create a brighter future for the community. Support our mission by donating, volunteering, or partnering with us. Let’s work together to build a thriving and resilient community for all
          </p>
          <div className="padding30">
            <Swiper navigation
              pagination={{ clickable: true }} {...params}>{storiesRender}</Swiper>
            
          </div>
        </div>
      </div>
    );
  }
}

export default Blog;
