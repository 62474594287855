import React from "react";
import Button from '../ui-components/button/button';
// SCSS
import "./teamInfo.scss";

const about = () => (
  <div className="team__info flex-center aboutus-mobile">
    <div>
      <h4 className="font20 weight800">Little About Us</h4>
      <p className="font14 weight500">
      LCI is governed by a Board of Directors, responsible for strategic direction and oversight. Our Executive Director leads the management team, which includes program and project staff, community engagement officers, and administrative support. We also work with a network of community volunteers, partners, and collaborators to deliver our programs and services.
      </p>
      <Button label="Read More" target={"blog"} />
      
    </div>
  </div>
);

export default about;
