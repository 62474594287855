import React from "react";
import { Row, Col } from "react-flexbox-grid";
// SCSS
import "./hero.scss";
//Assets
import HeroImage from '../../assets/hero/education.jpg';
//Components
import Button from '../ui-components/button/button';

const hero = () => (
  <div className="hero" id="hero">
    <div className="wrapper">
      <Row>
        <Col md={12} lg={6}>
          <div className="hero-info">           
            <h1 className="weight800 font40">
            Introduction to LCI
            </h1>
            <p className="font14">
            Welcome to Luwero Community Initiative (LCI), a leading not for profit Organisation dedicated to transform lives in the sub counties of Nyimbwa, Makulubita and Katikamu in Katikamu south Constituency in Luwero District, Uganda. We work hand in hand with local communities to identify and address their most pressing needs, empowering them to thrive and we're dedicated to providing the support and resources needed to make that happen.
            </p>
            <Button label="DONATE HERE" target={"campaigns"} />
          </div>
        </Col>
        <Col md={12} lg={6}>
          <div className="hero-image">
            <img src={HeroImage} alt="hero" />
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

export default hero;
